import { CrmIntegration } from "./DataIntegration/CrmIntegration";
import { CsvIntegration } from "./DataIntegration/CsvIntegration";
import { IProjectModel } from "../../../stores/project/projectModel";
import { IuploadDataModel } from "../../../stores/uploadData";
import { SdkIntegration } from "./SdkIntegration";
import { useEffect, useState } from "react";
import { BlingsTabs } from "../../../components/BlingsTabs";
import "./SiteIntegration.scss";
import { Divider, Popover } from "antd";
import { LandingPageIntegrationState } from "./DataIntegration/LandingPageIntegration/LandingPageIntegration";
import { useMst } from "../../../stores/Root";
import { observer } from "mobx-react-lite";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
};
export const SiteIntegration = observer((props: Props) => {
  const { project, uploadData } = props;

  const {
    title: projectTitle,
    allowCrmConnect,
    allowSdkConnect,
    allowDataConnect,
  } = project;
  const savedState = useMst((store) => {
    const { projectsStore } = store;
    const config = projectsStore.selectedProject?.minisiteConfig;
    const aliasId = projectsStore.selectedProject?.aliasId;
    const savedState = {
      alias: aliasId,
      scenes: config?.scenes || [],
      title: config?.title || projectTitle,
      description: config?.description || "",
      thumbnail: config?.thumbUrl || "",
      favicon: config?.faviconUrl || "",
      preCreate: config?.preCreate || "",
    };
    return savedState;
  });

  const [state, setState] = useState<LandingPageIntegrationState>(savedState);
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    const firstEnabledIndex = buttons.findIndex((btn) => !btn.disabled);
    setSelectedTab(firstEnabledIndex);
  }, []);
  const buttons = [
    {
      title: "CRM Integration",
      subtitle:
        "Integrate data from your CRM account in this project. Suitable for up to 4 dynamic keys.",
      disabled: !allowCrmConnect,
    },
    {
      title: "Spreadsheet (CSV)",
      subtitle:
        "Used for un-sensitive data. Mostly for you to get the feeling of Blings.io solution.",
      disabled: !allowDataConnect,
    },
    {
      title: "SDK",
      subtitle: "Use our SDK to have full control of your customization.",
      disabled: !allowSdkConnect,
    },
  ];
  const content = [
    <CrmIntegration
      project={project}
      state={state}
      setState={setState}
      savedState={savedState}
    />,
    <CsvIntegration
      project={project}
      state={state}
      setState={setState}
      savedState={savedState}
    />,
    <SdkIntegration project={project} uploadData={uploadData} />,
  ];
  return (
    <div className="SiteIntegration">
      <div className="tab-buttons">
        {buttons.map((button, index) => {
          return (
            <Popover
              {...(!button.disabled && { open: false })}
              content={
                <div>
                  Contact Blings support to add other integration options
                </div>
              }
              title="Not Authorized"
              key={index}
            >
              <div
                id={`tab-button-${index}`}
                onClick={() => !button.disabled && setSelectedTab(index)}
                key={index}
                className={
                  (selectedTab === index ? "selected-tab-button" : "") +
                  " tab-button " +
                  (button.disabled ? "disabled" : "")
                }
              >
                <div className="title">{button.title}</div>
                <div className="subtitle">{button.subtitle}</div>
              </div>
            </Popover>
          );
        })}
      </div>
      <Divider className="tab-divider" />
      <BlingsTabs content={content} currentTab={selectedTab} />
    </div>
  );
});
