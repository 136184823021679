import { Form, Popover, Select } from "antd";
import { BlingsBtn } from "../../../../../components/antd-extensions/blings-btn.component";
import { observer } from "mobx-react-lite";
import { IProjectModel } from "../../../../../stores/project/projectModel";
import { BlingsContainer } from "../../../../../components/BlingsContainer/BlingsContainer";
import "./LandingPageIntegration.scss";
import { BlingsInput } from "../../../../../components/BlingsInput/BlingsInput";
import { useMst } from "../../../../../stores/Root";
import { UploadFile } from "antd/lib/upload";
import { flattenSchema } from "../../../../../helpers/jsonSchema.helpers";
import { getRealENV } from "../../../../../config";
import { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import facebookExample from "../../../../../assets/integration/facebook-example.png";
import {
  DescriptionSvg,
  ThumbnailSvg,
  TitleCsv,
} from "../../../../../assets/Icons";
import { BlingsAnchorButton } from "../../../../../components/BlingsAnchorButton/BlingsAnchorButton";
import { JsEditor } from "./CodeEditor/JsEditor";

export type PlayerIntegrationData = {
  title: string;
  description: string;
  thumbUrl: string;
  alias: string;
  scenes: Array<string>;
  preCreate: string;
};
export type LandingPageIntegrationState = {
  alias: string;
  scenes: Array<string>;
  title: string;
  description: string;
  thumbnail: string;
  preCreate: string;
};
type Props = {
  project: IProjectModel;
  state: LandingPageIntegrationState;
  setState: React.Dispatch<React.SetStateAction<LandingPageIntegrationState>>;
  savedState: LandingPageIntegrationState;
};
export const LandingPageIntegration = observer((props: Props) => {
  const { project, state, setState, savedState } = props;
  const [showFeedback, setShowFeedback] = useState(false);
  const [aliasAlreadyInUse, setAliasAlreadyInUse] = useState(false);
  const [currentOpenField, setCurrentOpenField] = useState<number>(1);
  const clickField = (fieldClicked: number) => {
    if (fieldClicked === currentOpenField) {
      setCurrentOpenField(0);
    } else {
      setCurrentOpenField(fieldClicked);
    }
  };
  const {
    videoPartNames,
    projectAccountDomain,
    saveMiniSiteConfig: saveConfig,
    saveMiniSiteConfigStatus: opStatus,
    stateJsonSchema,
    account,
  } = project;

  const accountAliasId = account?.aliasId || "";
  const {
    uploadAssetToProject,
  }: {
    uploadAssetToProject: (
      file: File | UploadFile<any>,
      s3Folder: string,
      cb?: ((key: string) => void) | undefined,
      progressCallback?: any
    ) => Promise<void>;
  } = useMst((state) => {
    const { uploadAssetToProject } = state.liveControlStore;
    return { uploadAssetToProject };
  });

  async function save() {
    const { alias, scenes, title, description, thumbnail, preCreate } = state;
    if (!alias || !scenes.length || !title) {
      return setShowFeedback(true);
    }
    const merged: PlayerIntegrationData = {
      alias,
      scenes,
      title,
      description,
      thumbUrl: thumbnail,
      preCreate,
    };
    try {
      await saveConfig(merged);
      setAliasAlreadyInUse(false);
    } catch (e) {
      setAliasAlreadyInUse(true);
    }
  }
  const { alias, scenes, title, description, thumbnail, preCreate } = state;
  const env = getRealENV();
  const flatSchema = flattenSchema(stateJsonSchema);
  const params = Object.keys(flatSchema)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          flatSchema[key]?.toString() || ""
        )}`
    )
    .join("&");
  const link = `https://${accountAliasId}.${projectAccountDomain}/${
    env === "master" ? "" : `${env}/`
  }${alias}?${params}`;
  return (
    <div className="LandingPageIntegration">
      <div>
        <h1 className="page-subtitle">1. Personalize Your Landing Page HTML</h1>
        <p className="sub-text-1">
          Once your users click the personalized link - they can watch their own
          MP5. We already created a responsive landing page for you. Easily
          customize it to align with your brand, or download the HTML for full
          SDK customization.{" "}
        </p>
      </div>
      <div className="body">
        <BlingsContainer>
          <div className="content">
            <div className="form">
              <div>
                <h2 className="form-subtitle">General</h2>
                <Form>
                  <Form.Item
                    {...(((showFeedback && !alias) || aliasAlreadyInUse) && {
                      validateStatus: "error",
                      help: aliasAlreadyInUse
                        ? "URL alias already in use by another project"
                        : "Invalid URL alias",
                    })}
                  >
                    <BlingsInput
                      value={alias}
                      handleOnFocus={() => setCurrentOpenField(1)}
                      label="* URL Alias"
                      onChange={(e) => {
                        e = e.replace(/[^a-z0-9_-]/g, "");
                        setState({ ...state, alias: e });
                      }}
                    />
                    {/* <Input value={alias} onChange={e => setState({ ...state, alias: e.target.value })} /> */}
                    <Popover
                      className="tooltip-integration"
                      placement="rightTop"
                      open={currentOpenField === 1}
                      overlayInnerStyle={{ width: "250px" }}
                      getPopupContainer={(trigger) =>
                        trigger?.parentElement || document.body
                      }
                      content={
                        <ul className="extra-info-popover">
                          <li>
                            <b>What is this:</b> This is what comes after the
                            URL domain. For example:{" "}
                            <span
                              style={{ whiteSpace: "nowrap", color: "red" }}
                            >
                              {`https://{account}.${projectAccountDomain}/{alias}`}
                            </span>
                          </li>
                          <li>
                            <b>Keep it short:</b> We recommend keeping this as
                            short as possible.
                          </li>
                          <li>
                            <b>Allowed:</b> Lowercase letters, dash (-),
                            underscore (_) and numbers.
                          </li>
                          <li>
                            <b>Not Allowed:</b> No spaces, no special characters
                          </li>
                        </ul>
                      }
                    >
                      <div onClick={() => clickField(1)}>
                        <QuestionCircleOutlined
                          style={{
                            color: "var(--blings_icon_gray)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Popover>
                  </Form.Item>
                  <Form.Item
                    className="form-item-scene"
                    {...(showFeedback &&
                      !scenes.length && {
                        validateStatus: "error",
                        help: "Invalid",
                      })}
                  >
                    <p id="scenes-label">* Scene Lineup (Required)</p>
                    <Select
                      style={{ marginBottom: "1rem" }}
                      onFocus={() => setCurrentOpenField(2)}
                      value={scenes}
                      onChange={(value) =>
                        setState({ ...state, scenes: value })
                      }
                      mode="multiple"
                      /* allowClear
                    style={{ minWidth: 200 }} */
                    >
                      {videoPartNames.map((videoPartName) => (
                        <Select.Option
                          key={videoPartName}
                          value={videoPartName}
                        >
                          {videoPartName}
                        </Select.Option>
                      ))}
                    </Select>
                    <Popover
                      className="tooltip-integration"
                      placement="right"
                      overlayInnerStyle={{ width: "250px" }}
                      open={currentOpenField === 2}
                      getPopupContainer={(trigger) =>
                        trigger?.parentElement || document.body
                      }
                      content={
                        <ul className="extra-info-popover">
                          <li>
                            Some MP5 videos are dynamic and all you need to show
                            is the first scene
                          </li>
                          <li>
                            Some videos require adding a scene lineup - if this
                            is the case add it here according to the order you
                            wish to show it.
                          </li>
                        </ul>
                      }
                    >
                      <div onClick={() => clickField(2)}>
                        <QuestionCircleOutlined
                          style={{
                            color: "var(--blings_icon_gray)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Popover>
                  </Form.Item>
                </Form>
              </div>
              <div>
                <h2 className="form-subtitle">
                  Meta Tags: Social Sharing Appearance
                </h2>
                <p id="form-subtitle-extra">
                  Control the metatags - how will a post look like what sharing
                  your page.
                </p>
                <Form>
                  <Form.Item
                    {...(showFeedback &&
                      !title && { validateStatus: "error", help: "Invalid" })}
                  >
                    <BlingsInput
                      handleOnFocus={() => setCurrentOpenField(3)}
                      value={title}
                      label="* Page title"
                      onChange={(e) => setState({ ...state, title: e })}
                    />
                    <Popover
                      className="tooltip-integration"
                      placement="right"
                      open={currentOpenField === 3}
                      style={{ zIndex: "0" }}
                      getPopupContainer={(trigger) =>
                        trigger?.parentElement || document.body
                      }
                      title="Your title will appear when sharing"
                      content={
                        <>
                          <div>
                            <div
                              style={{
                                position: "absolute",
                                top: "145px",
                                left: "80px",
                              }}
                            >
                              <TitleCsv />
                            </div>
                            <img src={facebookExample} />
                          </div>
                        </>
                      }
                    >
                      <div onClick={() => clickField(3)}>
                        <QuestionCircleOutlined
                          style={{
                            color: "var(--blings_icon_gray)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Popover>
                  </Form.Item>
                  <Form.Item>
                    <BlingsInput
                      value={description}
                      label="Page description"
                      handleOnFocus={() => setCurrentOpenField(4)}
                      onChange={(e) => setState({ ...state, description: e })}
                    />
                    <Popover
                      className="tooltip-integration"
                      open={currentOpenField === 4}
                      getPopupContainer={(trigger) =>
                        trigger?.parentElement || document.body
                      }
                      placement="right"
                      title="Your description will appear when sharing"
                      content={
                        <>
                          <div>
                            <div
                              style={{
                                position: "absolute",
                                top: "155px",
                                left: "80px",
                              }}
                            >
                              <DescriptionSvg />
                            </div>
                            <img src={facebookExample} />
                          </div>
                        </>
                      }
                    >
                      <div onClick={() => clickField(4)}>
                        <QuestionCircleOutlined
                          style={{
                            color: "var(--blings_icon_gray)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Popover>
                  </Form.Item>
                  <Form.Item>
                    <BlingsInput
                      value={thumbnail}
                      label="Thumbnail to show when sharing"
                      handleOnFocus={() => setCurrentOpenField(5)}
                      onChange={(e) => setState({ ...state, thumbnail: e })}
                      uploadAssetsToProject={uploadAssetToProject}
                    />
                    <Popover
                      className="tooltip-integration"
                      open={currentOpenField === 5}
                      getPopupContainer={(trigger) =>
                        trigger?.parentElement || document.body
                      }
                      placement="right"
                      title="Your thumbnail will appear when sharing"
                      content={
                        <>
                          <div>
                            <div
                              style={{
                                position: "absolute",
                                top: "145px",
                                left: "10px",
                              }}
                            >
                              <ThumbnailSvg />
                            </div>
                            <img src={facebookExample} />
                          </div>
                        </>
                      }
                    >
                      <div onClick={() => clickField(5)}>
                        <QuestionCircleOutlined
                          style={{
                            color: "var(--blings_icon_gray)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Popover>
                  </Form.Item>
                  <details about="Advanced">
                    <summary className="form-subtitle">Advanced</summary>
                    <Form.Item>
                      <div className="code-editor-container">
                        <label>Pre create code:</label>
                        <JsEditor
                          defaultValue={preCreate}
                          onChange={(e) => {
                            setState({ ...state, preCreate: e });
                          }}
                        />
                      </div>
                      <Popover
                        open={currentOpenField === 7}
                        overlayInnerStyle={{ width: "250px" }}
                        getPopupContainer={(trigger) =>
                          trigger?.parentElement || document.body
                        }
                        className="tooltip-integration"
                        placement="rightBottom"
                        title="Pre-create code"
                        content={`Code to be executed before the player is created. Modify the "params" object to change the player's behavior.`}
                      >
                        <div onClick={() => clickField(7)}>
                          <QuestionCircleOutlined
                            style={{
                              color: "var(--blings_icon_gray)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Popover>
                    </Form.Item>
                  </details>
                </Form>
              </div>
            </div>

            <div className="footer">
              <BlingsAnchorButton
                href={link}
                className="link"
                disabled={JSON.stringify(savedState) !== JSON.stringify(state)}
              >
                {JSON.stringify(savedState) !== JSON.stringify(state)
                  ? "Save to preview your page"
                  : "Preview your page"}
              </BlingsAnchorButton>
              <BlingsBtn opState={opStatus} onClick={save}></BlingsBtn>
            </div>
          </div>
        </BlingsContainer>
        {/* <div className="video-container">
          <video src="https://joy1.videvo.net/videvo_files/video/free/2013-08/large_watermarked/hd0992_preview.mp4"></video>
        </div> */}
      </div>
    </div>
  );
});

const Tab = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 964.04 161.79" /* style={"enable-background:new 0 0 964.04 161.79;"} */
    >
      <style type="text/css"></style>
      <g id="Layer_3">
        <path
          fill="currentColor"
          d="M933.54,144c0,0-25.41,0.66-25.41-41.58V91.88v-40c0-15.96-13.06-29.01-29.01-29.01H73.14
   c-15.96,0-29.01,13.06-29.01,29.01v40v10.54c0,42.24-25.41,41.58-25.41,41.58h-88.09v75.39H44.13h864h113.51V144H933.54z"
        />
      </g>
      <g id="Layer_2">
        <g>
          <line
            style={{
              fill: "none",
              stroke: "#FFFFFF",
              strokeWidth: 6,
              strokeLinecap: "round",
              strokeLinejoin: "bevel",
              strokeMiterlimit: 10,
            }}
            x1="842.5"
            y1="73.24"
            x2="866.11"
            y2="96.85"
          />
          <line
            style={{
              fill: "none",
              stroke: "#FFFFFF",
              strokeWidth: 6,
              strokeLinecap: "round",
              strokeLinejoin: "bevel",
              strokeMiterlimit: 10,
            }}
            x1="866.11"
            y1="73.24"
            x2="842.5"
            y2="96.85"
          />
        </g>
      </g>
    </svg>
  );
};
