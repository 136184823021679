import { ExperimentOptimizationTechnique } from "../../../../API";
import { useMst } from "../../../../stores/Root";
import BlingsRadio from "../../../BlingsRadio/BlingsRadio";
import AIImage from "../../../../assets/aiImage.png";
import "./OptimizationPanel.scss";
import { StarsIcon } from "../../../../assets/Icons";
import { useState } from "react";

type Props = {
  setShouldShowOptimizationSelectionPanel: (value: boolean) => void;
};

export default function OptimizationPanel({
  setShouldShowOptimizationSelectionPanel,
}: Props) {
  // State declarations
  const {
    optimizationTechnique,
    setExperimentOptimizationTechnique,
    changeOptimizationTechnique,
  } = useMst((store) => {
    return {
      optimizationTechnique:
        store.experimentStore.selectedExperimentOptimizationTechnique,
      setExperimentOptimizationTechnique:
        store.experimentStore.setExperimentOptimizationTechnique,
      changeOptimizationTechnique:
        store.projectsStore.selectedProject?.changeExperimentOptimizationType,
    };
  });
  const [isChangingOptimization, setIsChangingOptimization] = useState(false);

  const optimizationOptions = [
    {
      title: "Conversion",
      description: "For Conversion, you need to have at least 1 CTA defined",
      value: ExperimentOptimizationTechnique.CONVERSION,
      key: ExperimentOptimizationTechnique.CONVERSION,
    },
    {
      title: "Watch time",
      description: "Still need to be written",
      value: ExperimentOptimizationTechnique.WATCH_TIME,
      key: ExperimentOptimizationTechnique.WATCH_TIME,
    },
    {
      title: "Engagement",
      description: "Still need to be written",
      value: ExperimentOptimizationTechnique.ENGAGEMENT,
      key: ExperimentOptimizationTechnique.ENGAGEMENT,
    },
    {
      title: "Blings.io Success Factor",
      description: "Still need to be written",
      value: ExperimentOptimizationTechnique.BLINGS_MAGIC,
      key: ExperimentOptimizationTechnique.BLINGS_MAGIC,
    },
    {
      title: "Disabled",
      description: "Still need to be written",
      value: ExperimentOptimizationTechnique.DISABLED,
      key: ExperimentOptimizationTechnique.DISABLED,
    },
  ];

  return (
    <div className="ai-optimization-panel-container">
      {isChangingOptimization ? (
        <div className="ai-optimization-panel-overlay">
          <BlingsRadio
            options={optimizationOptions}
            dir="vertical"
            optionTitleStyle={{ fontSize: "12px", color: "#1E1E1E" }}
            descriptionStyle={{
              fontSize: "12px",
              color: "#A6A8B1",
            }}
            onChange={(selectionIndex) => {
              setExperimentOptimizationTechnique(
                optimizationOptions[selectionIndex].value
              );
              changeOptimizationTechnique();
            }}
          />
        </div>
      ) : (
        <>
          <img
            src={AIImage}
            alt="AI"
            style={{ width: "220px", height: "193px" }}
          />
          <div className="ai-optimization-panel-header">
            <StarsIcon width={36} height={36} />
            <h3>
              Video optimized for{" "}
              <span>
                {optimizationTechnique
                  ? optimizationTechnique.replace("_", " ")
                  : ""}
              </span>
            </h3>
          </div>
          <p>
            Ai optimization enables you to optimize your video Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </p>
          <button
            className="experiment-panel-update-goal-button"
            onClick={() => {
              setShouldShowOptimizationSelectionPanel(true);
            }}
          >
            Update video goal
          </button>
        </>
      )}
    </div>
  );
}
