import * as React from "react";
import { IProjectModel } from "../../stores/project/projectModel";
import { useRef, useState, useEffect } from "react";
import { Select } from "antd";
import { Player } from "@blings/blings-player";
import PlayerManager, { getPlayerMaxHeight } from "../../utils/playerManager";
import { getENV } from "../../config";
import { observer } from "mobx-react-lite";
import LoadingSpinner from "../../assets/Ellipse 7.png";
import { EXAMPLE_PLAYER_LINK } from "../../consts";
import { ISdkSettings } from "@blings/blings-player/lib/src/SDK/sdk.api";
import useDebounce from "../../helpers/DebounceHook";
import { rootStore, useMst } from "../../stores/Root";
import {
  NotificationType,
  InstantNotification,
} from "../../types/Notification";
import { Notification } from "../../stores/notification/notificationModel";
import { FrameIndicator } from "./FrameIndicator";
import { toast } from "react-toastify";
import { Instance } from "mobx-state-tree";
import { CopyUrl, ExportMp4Icon, ShowInDemoPageIcon } from "../../assets/Icons";
import GlobalMuteButton from "../../components/GlobalMuteButton";

type Props = {
  project: IProjectModel;
  data: string;
  selectedDataID?: string;
  settings?: Partial<ISdkSettings>;
  recordChanged?: boolean;
  frameIndicator?: boolean;
  renderMp4?: boolean;
  showExtraOptions?: boolean;
};

interface LinkProps extends Props {
  scenes?: string[];
  selectedDataID?: string;
}

interface InnerProps extends Props {
  // project: IProjectModel;
  // data: string;
  scenes?: string[];
  vertical: boolean;
  settings: any;
  frameIndicator?: boolean;
  playerVersionToUse: string | undefined;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

/**
 * Component to render the Blings Player using dynamic data
 * @param props - All the data in one object. This data is:
 * - project: IProjectModel; - The project model containing the project ID.
 * - data: string; - The data of the dynamic data for the player.
 * - scenes?: string[]; - The scenes for the video that the player must load.
 * - settings?: Partial<ISdkSettings>; - The settings for the player.
 * @returns {React.FC<LinkProps>} - A div component where the player will be rendered.
 */
export const DemoSdkPlayerInner = ({
  project,
  data,
  scenes,
  settings,
  frameIndicator,
  playerVersionToUse,
  setIsLoading,
  isLoading,
}: InnerProps) => {
  // This is the container reference to where the player will be rendered
  // This will reference the div element that is always rendered
  // And the player SDK will be rendered inside this div
  const containerRef = useRef<HTMLDivElement>(null);

  // This flag will be used to control if the player can be created or not
  // If a player is already created or dont exists, this flag will be set to true
  // If a player is being created, this flag will be set to false
  const [canCreatePlayer, setCanCreatePlayer] = useState(true);

  // This will be used to control if the player exists or not
  // const [player, setPlayer] = useState<Player | null>(null);

  // This will be used to check if the player needs a new configuration
  // If this is different from the last configuration, the player will be recreated
  const [lastConfig, setLastConfig] = useState<string>("");
  const [actualConfig, setActualConfig] = useState<string>("");
  const [currentFrame, setCurrentFrame] = useState<string>("");
  const [totalFrames, setTotalFrames] = useState<string>("");
  // This function will be used to create the player with the actual configuration
  function CreatePlayer() {
    if (!canCreatePlayer) {
      return;
    }
    setCanCreatePlayer(false);

    // Check if a player already exists
    if ((window as any).p) {
      // If a player already exists, we want to destroy it
      try {
        const lastIndex = (window as any).p.index;
        (window as any).p.stop();
        (window as any).p.destroy();
        // Clean old players if exists
        if ((window as any).BlingsPlayer)
          Object.keys((window as any).BlingsPlayer.players).forEach((key) => {
            if (key < lastIndex) {
              (window as any).BlingsPlayer.players[key].destroy();
            }
          });
      } catch (e) {
        console.error("err destroying player", e);
      }
    }
    // Create the object that will be used to create the player
    const { project, data, settings, scenes }: InnerProps =
      JSON.parse(actualConfig);
    // Create the player
    if (containerRef.current && scenes?.length) {
      containerRef.current.innerHTML = "";
      // Create the player
      new PlayerManager();

      // Get the major version of the player
      let majorVersion = parseInt(playerVersionToUse?.split(".")[0] as string);
      if (Number.isNaN(majorVersion)) majorVersion = -1;
      setIsLoading(true);
      PlayerManager.get()
        .createPlayer(
          {
            project: { env: getENV(), projectId: project.id },
            data,
            settings: { container: containerRef.current, ...settings },
            scenes,
          },
          {
            playerMajorVersion: majorVersion,
          }
        )
        .then((p: Player) => {
          setIsLoading(false);
          // Store the player on window, on a state and set the flag to allow creation of new players
          (window as any).p = p;
          setCanCreatePlayer(true);
          // Set the last configuration used to the current configuration
          // The last config defines what the player was created with
          setLastConfig(actualConfig);
          setCurrentFrame(p.animation.currentFrame.toString());
          setTotalFrames(p.animation.totalFrames.toString());
          p.EE.on("onFrame", () => {
            setCurrentFrame(p.animation.currentFrame.toString());
          });
        });
    } else {
      setCanCreatePlayer(true);
      setLastConfig(actualConfig);
    }
  }

  // This will be used to control if the player is being created or not
  useEffect(() => {
    // Store the actual config on a state for access for later verification
    setActualConfig(
      JSON.stringify({
        project,
        scenes,
        data,
        settings,
        playerVersionToUse,
      })
    );
  }, [project.id, scenes, data, settings, playerVersionToUse]);

  // Try to create the player always that the actual config is changed
  useEffect(() => {
    setLastConfig(actualConfig);
    if (actualConfig !== "") CreatePlayer();
  }, [actualConfig]);

  // This will be used to control if the player needs a new configuration, so a new player needs to be created
  useEffect(() => {
    if (canCreatePlayer) {
      if (actualConfig !== lastConfig) {
        CreatePlayer();
      }
    }

    const handleResize = (): void => {
      getPlayerMaxHeight(containerRef?.current);
    };
    if (containerRef?.current) {
      getPlayerMaxHeight(containerRef?.current);
      window.addEventListener("resize", handleResize);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [canCreatePlayer, containerRef]);
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "fit-content",
      }}
    >
      <div
        ref={containerRef}
        style={{
          maxWidth: "800px",
          borderRadius: "8px",
        }}
        id={Math.random().toString(36).substring(7)}
      />{" "}
      {frameIndicator ? (
        !isLoading ? (
          <div className="frame-indicator-wrapper">
            <GlobalMuteButton />
            <FrameIndicator
              currentFrame={currentFrame}
              totalFrames={totalFrames}
            />
          </div>
        ) : scenes && scenes.length && scenes.filter((a) => !!a).length ? (
          <div className="spinner" style={{ marginTop: "0" }}>
            <img src={LoadingSpinner} className="Rotate" alt="Loading Icon" />
          </div>
        ) : (
          <div>No Scenes</div>
        )
      ) : (
        isLoading && (
          <div className="player-spinner spinner" style={{ marginTop: "0" }}>
            <img src={LoadingSpinner} className="Rotate" alt="Loading Icon" />
          </div>
        )
      )}
    </div>
  );
};

/**
 * This function will get all the video values from the current player and send it to the message service to add it to the server render queue
 */
async function CallServerRender(
  project: any,
  scenes: any,
  data: any,
  selectedDataID: any,
  recordChanged: any
) {
  /* Format that the server render accepts
  { 
      "data": { "firstName": "Carlos"},
      "scenes": ["Main"],
      "projectId": "Pilot_SDR",
      "env": "dev",
      "quality": "1",
      "outputFileName": "teste"
      "playerVersion": "3-1-5"
  } 
  */
  const serverRenderData: any = {
    data,
    scenes,
    projectId: project.id,
    env: getENV(),
    quality: "3", // Medium quality
  };
  if (project.playerVersionToUse)
    serverRenderData["playerVersion"] = project.playerVersionToUse.replace(
      /[\.]/g,
      "-"
    );

  const authSession = rootStore.accountStore.AuthSession;
  const userCredentials = authSession.tokens?.accessToken.toString();

  toast(`MP4 rendering requested!`, {
    position: "bottom-left",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const url =
    getENV() === "dev" //"http://127.0.0.1:3000/start-create" : "https://mp4.blings.io/start-create"
      ? //"http://localhost:3000/start-create" : ""
        "https://cors.blings.io/http://54.170.15.161:3000/start-create"
      : "https://mp4.blings.io/start-create";
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userCredentials,
    },
    body: JSON.stringify(serverRenderData),
  })
    .then((response) => {
      // Listen to subscriptions from the server render
      // creates a toast notification when receive a instant notification
      // and creates a toast notification when receive a normal notification
      try {
        rootStore.notificationStore.addNotificationSubscription({
          id: "render-callback-normal" + project.id,
          type: [NotificationType.Render],
          subType: ["rendering"],
          executeOnlyOnce: true, // shall only receive 1 normal notification, the one that says the render is done
          sendType: "Normal",
          callback: (
            notification: Instance<typeof Notification> | InstantNotification
          ) => {
            //console.log("Normal notification", notification);
            toast(
              `Your render has started. We will notify you when your file is ready.`,
              {
                position: "bottom-left",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          },
        });
      } catch (error) {
        console.error(error);
      }
    })
    .catch((error) => {
      toast(`Error when trying to render. Please try again later...`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
}

export function DemoSdkExternalLink({
  project,
  scenes,
  selectedDataID,
  data,
  recordChanged,
  renderMp4 = false,
}: LinkProps) {
  let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${project.id}`;
  if (scenes) {
    url += `&scenes=${encodeURIComponent(scenes.join())}`;
  }

  if (selectedDataID) {
    url += `&id=${selectedDataID}`;
  }

  if (data && (!selectedDataID || recordChanged)) {
    url += `&data=${encodeURIComponent(JSON.stringify(data))}`;
  }

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "8px",
          alignItems: "selfStart",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <a
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
            paddingRight: "20px",
          }}
          onClick={() => {
            const currentVersion =
              PlayerManager.get().GetCurrentPlayerVersion();
            // Do nothing if the player has the latest version
            if (currentVersion === "latest") {
            }
            // If there is no player version, use the one that the project has specified
            else if (currentVersion === "WARN_NO_PLAYER_VERSION") {
              console.warn(
                "No player version specified, using project version"
              );
              console.warn("Project version: ", project?.playerVersionToUse);
              url += `&v=${(project?.playerVersionToUse as string).replaceAll(
                ".",
                "-"
              )}`;
            }
            // If there is a player version, use that
            else {
              url += `&v=${currentVersion}`;
            }
            // Open the URL trough code
            window.open(url, "_blank");
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ShowInDemoPageIcon /> Watch preview in demo page
        </a>
        <a
          style={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            paddingRight: "20px",
          }}
          onClick={() => {
            const currentVersion =
              PlayerManager.get().GetCurrentPlayerVersion();
            // Do nothing if the player has the latest version
            if (currentVersion === "latest") {
            }
            // If there is no player version, use the one that the project has specified
            else if (currentVersion === "WARN_NO_PLAYER_VERSION") {
              console.warn(
                "No player version specified, using project version"
              );
              console.warn("Project version: ", project?.playerVersionToUse);
              if (url.includes("&v="))
                url = url.replace(
                  /&v=.*/,
                  `&v=${(project?.playerVersionToUse as string).replaceAll(
                    ".",
                    "-"
                  )}`
                );
              else
                url += `&v=${(project?.playerVersionToUse as string).replaceAll(
                  ".",
                  "-"
                )}`;
            }
            // If there is a player version, use that
            else {
              if (url.includes("&v="))
                url = url.replace(/&v=.*/, `&v=${currentVersion}`);
              else url += `&v=${currentVersion}`;
            }
            toast(`Copied to clipboard`, {
              position: "bottom-left",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigator.clipboard.writeText(url);
          }}
        >
          <CopyUrl /> Copy preview URL
        </a>
        {renderMp4 ? (
          <a
            style={{
              display: "flex",
              gap: "7px",
              paddingRight: "20px",
              alignItems: "center",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              CallServerRender(
                project,
                scenes,
                data,
                selectedDataID,
                recordChanged
              );
            }}
          >
            <ExportMp4Icon /> Export preview to MP4
          </a>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export const DemoSdkPlayer = observer(
  ({
    project,
    data,
    selectedDataID,
    settings,
    recordChanged,
    frameIndicator,
    renderMp4,
    showExtraOptions = true,
  }: Props) => {
    const { videoPartNames, minisiteConfig } = project;
    const selectedScensFromMinisite = minisiteConfig?.scenes;
    const [scenes, setScenes] = useState<string[]>(() => {
      if (selectedScensFromMinisite && selectedScensFromMinisite.length > 0)
        return selectedScensFromMinisite;
      if (videoPartNames && videoPartNames.length > 0)
        return [videoPartNames[0]];
      return [];
    });
    const [isLoading, setIsLoading] = useState(true);

    const selectedScenes = project.workspaceVideoParts?.filter(
      (vp) => vp.name && scenes.includes(vp.name)
    );
    const playerVersionToUse = project.playerVersionToUse || undefined;
    const debouncedSettings = useDebounce<any>(settings, 1000);
    const debouncedScenes = useDebounce<any>(scenes, 1000);
    const vertical = selectedScenes?.length
      ? selectedScenes[0]?.jsonData?.h > selectedScenes[0]?.jsonData?.w
      : false;

    useEffect(() => {
      return () => {
        PlayerManager.get().removeCurrentPlayer();
      };
    }, []);
    return (
      <div style={{ display: "flex", gap: "2rem" }}>
        {scenes.length ? (
          <div style={{ minWidth: "16rem" }}>
            <DemoSdkPlayerInner
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              project={project}
              data={data}
              scenes={debouncedScenes}
              vertical={vertical}
              settings={debouncedSettings}
              frameIndicator={frameIndicator}
              playerVersionToUse={playerVersionToUse}
            />
          </div>
        ) : (
          <div style={{ width: "300px" }}></div>
        )}
        {videoPartNames?.length >= 1 && showExtraOptions && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div style={{ display: "flex" }}>
              <p
                style={{
                  minWidth: "fitContent",
                  fontWeight: "600",
                  marginBottom: "0",
                  alignSelf: "center",
                  marginRight: "0.5rem",
                }}
              >
                Watching:
              </p>
              <Select
                mode="multiple"
                allowClear
                onChange={setScenes}
                className="SelectScene"
                value={scenes}
                style={{ minWidth: 200 }}
                suffixIcon={null}
              >
                {videoPartNames.map((vp) => (
                  <Select.Option key={vp} value={vp}>
                    {vp}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {!isLoading && (
              <DemoSdkExternalLink
                project={project}
                data={data}
                scenes={scenes}
                selectedDataID={selectedDataID}
                settings={settings}
                recordChanged={recordChanged}
                renderMp4={renderMp4}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
