import { observer } from "mobx-react-lite";
import { useMst } from "../../../stores/Root";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import { ExperimentOptimizationTechnique } from "../../../API";
import ExperimentPanel from "../../../components/AI/Optimization/VariantPanel/ExperimentPanel";
import EnableOptimizationPanel from "../../../components/AI/Optimization/OptimizationPanel/EnableOptimizationPanel";
import "./AIOptimization.scss";
import OptimizationPanel from "../../../components/AI/Optimization/OptimizationPanel/OptimizationPanel";
import { StarsIcon } from "../../../assets/Icons";

type Props = {
  projectId: string;
};

function AIOptimization(props: Props) {
  // State declarations
  const {
    userCan,
    selectedExperimentOptimizationTechnique,
    projectAccountId,
    loadStatus,
  } = useMst((store) => {
    return {
      loadStatus: store.accountStore.loadStatus,
      userCan: store.accountStore.userCan,
      selectedExperimentOptimizationTechnique:
        store.experimentStore.selectedExperimentOptimizationTechnique,
      projectAccountId: store.projectsStore.selectedProject?.account?.id,
    };
  });
  const [userCanAccess, setUserCanAccess] = useState(
    userCan(projectAccountId as string, "aiOptimization")
  );
  const [_selectedSuccessFactor, setSelectedSuccessFactor] =
    useState<ExperimentOptimizationTechnique>(
      selectedExperimentOptimizationTechnique
    );
  const [
    shouldShowOptimizationSelectionPanel,
    setShouldShowOptimizationSelectionPanel,
  ] = useState(false);

  // useEffect declarations
  useEffect(() => {
    setSelectedSuccessFactor(
      selectedExperimentOptimizationTechnique ||
        ExperimentOptimizationTechnique.DISABLED
    );
  }, [selectedExperimentOptimizationTechnique]);

  useEffect(() => {
    setUserCanAccess(userCan(projectAccountId as string, "aiOptimization"));
  }, [loadStatus]);

  return (
    <div className="ai-page">
      <div className="ai-optimization-header">
        <h2>AI Optimization</h2>
        <h5>Optimize your video's performance with AI</h5>
      </div>
      <div className="ai-optimization-container">
        {!userCanAccess ? ( // If user can't access AI optimization we show the following
          <div className="ai-optimization-not-allowed">
            <StarsIcon />
            <div className="ai-optimization-not-allowed-content">
              <Typography.Text>AI is disabled for this account</Typography.Text>
              <Typography.Text>
                Contact sales for more information
              </Typography.Text>
            </div>
          </div>
        ) : (
          // If user can access AI optimization we need to check if the project has a selected variant optimization technique
          <div className="ai-optimization-container">
            {_selectedSuccessFactor !==
              ExperimentOptimizationTechnique.DISABLED &&
            !shouldShowOptimizationSelectionPanel ? (
              <>
                <OptimizationPanel
                  setShouldShowOptimizationSelectionPanel={
                    setShouldShowOptimizationSelectionPanel
                  }
                />
                <ExperimentPanel />
              </>
            ) : (
              <EnableOptimizationPanel
                setShouldShowOptimizationSelectionPanel={
                  setShouldShowOptimizationSelectionPanel
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(AIOptimization);
