import { types, Instance, getRoot } from "mobx-state-tree";
import {
  signOut,
  fetchAuthSession,
  fetchUserAttributes,
  fetchMFAPreference,
  FetchMFAPreferenceOutput,
} from "aws-amplify/auth";
import { generateClient, get, post } from "aws-amplify/api";
import { copy, remove } from "aws-amplify/storage";
import { getAccount } from "../graphql/queries";
import { PATHS, toPath } from "../PATHS";
import { GetAccountQuery, UpdateAccountMutation } from "../API";
import { updateAccount } from "../graphql/mutations";
import {
  ADMIN_GROUP,
  ENTERPRISE_PERMISSIONS,
  FREEMIUM_PERMISSIONS,
  NEW_REST_API,
  NEW_REST_API__V2__ACCOUNT_ALIAS_AVAILABLE,
  NEW_REST_API__V1__HUBSPOT_REFRESH_TOKEN,
  PRO_PERMISSIONS,
} from "../consts";
import {
  AccountPermissionsProps,
  AccountProps,
  AccountSettingsProps,
  FileUploadProps,
} from "./platformSchema";
import { IRootModel } from "./Root";
import { ITokenResponseIF } from "../view/Integrations/OAuthCallback";
import { NavigateFunction } from "react-router";
import { asyncOpStateType } from "./async-op-states";
import { AsyncOpState } from "../types/enums/async-op-states";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import { UserAccountMFA } from "../types/enums/UserAccountMFA";
import { AuthSession } from "../types/awsTypes";
import { deleteAuthCookies } from "@blings/shared-auth-component";
import { getENV } from "../config";

// Graphql Client
const client = generateClient();

const accountDataModel = types.model({
  // id: types.string,
  // name: types.string,
  ...AccountProps,
  // phone: types.maybeNull(types.string)
});
export type IAccountDataModel = Instance<typeof accountDataModel>;
const accountSettingsModel = types.model({
  ...AccountSettingsProps,
});
export type IAccountSettingsModel = Instance<typeof accountSettingsModel>;
const accountPermissionsModel = types.model({
  ...AccountPermissionsProps,
});
export type IAccountPermissionsModel = Instance<typeof accountPermissionsModel>;

const accountTypeModel = types.model({
  id: types.identifier,
  levelName: types.enumeration(["FREEMIUM", "PRO", "ENTERPRISE"]),
  permissions: accountPermissionsModel,
});
export type IAccountTypeModel = Instance<typeof accountTypeModel>;
const userAttributesModel = types.model({
  sub: types.string,
  identityId: types.string,
  email: types.string,
  phone_number: types.maybeNull(types.string),
  "custom:mfaConfig": types.maybeNull(types.string),
});
export type IUserAttributesModel = Instance<typeof userAttributesModel>;

const fileUploadModel = types.model({
  ...FileUploadProps,
  // originalName: types.string,
  createdAt: types.string,
  fileName: types.string,
  // fileStatus: types.string,
  // fileError: types.maybeNull(types.string),
  // numRecords: types.maybeNull(types.number),
  // numErrors: types.maybeNull(types.number),
});
export type IFileUploadModel = Instance<typeof fileUploadModel>;
export type IFileUploadsModel = Instance<typeof fileUploadModel>[];
const fontModel = types.model({
  fontFamily: types.string,
  variants: types.array(
    types.model({
      name: types.string,
      weight: types.number,
      style: types.string,
    })
  ),
});
export type IFontModel = Instance<typeof fontModel>;
export type IFontsModel = Instance<typeof fontModel>[];

// Model for a single account's fonts
const accountFontsModel = types.model({
  id: types.identifier, // The 'id' field will serve as a unique identifier for each account
  fonts: types.array(fontModel),
});

export const accountModel = types
  .model("accountStore", {
    cognitoGroupId: types.maybe(types.string),
    cognitoExtraGroupIds: types.optional(types.array(types.string), []),
    accountData: types.maybe(accountDataModel),
    accountSettings: types.maybe(accountSettingsModel),
    /**
     * This is the MFA configuration from the organization account settings (The account on dynamo DB)
     */
    organizationRequireMFA: types.maybeNull(types.boolean),
    /**
     * This is the MFA configuration from the user account settings (The account on cognito)
     * 0 - Unset - Follow the organization settings
     * 1 - Disabled - MFA is not required
     * 2 - Enabled - MFA is required
     */
    personalAccountMFARequired: types.maybeNull(types.number),
    mfaPreferences: types.maybeNull(types.string),
    mfaEnabledArray: types.optional(types.array(types.string), []),
    userAttributes: types.maybe(userAttributesModel),
    fileUploads: types.optional(types.array(fileUploadModel), []),
    hubspotConnection: types.maybeNull(types.string),
    saveAliasIdStatus: asyncOpStateType,
    // fonts: types.optional(types.array(fontModel), []),
    fontsPerAccountCollectionModel: types.optional(
      types.map(accountFontsModel),
      {}
    ),
    accountTypePerAccountCollectionModel: types.optional(
      types.map(accountTypeModel),
      {}
    ),
    uploadFontStatus: asyncOpStateType,
    loadStatus: types.optional(
      types.enumeration("LoadStatus", ["unloaded", "loading", "loaded"]),
      "unloaded"
    ),

    cognito_authSession: types.maybeNull(types.frozen()),
  })
  .volatile((self) => ({
    loadCallbackFunctions: [] as (() => void)[],
  }))
  .views((self) => ({
    get isAdmin() {
      return self.cognitoGroupId === ADMIN_GROUP;
    },
    get hubspotCredentialsAreValid() {
      if (!self.hubspotConnection) return false;
      const cred = JSON.parse(self.hubspotConnection);
      if (Date.now() >= cred.updatedAt + cred.expiresIn * 1000) {
        return false;
      }
      return true;
    },
    get mainGroupId() {
      return self.cognitoGroupId;
    },
    get extraGroupIds() {
      return self.cognitoExtraGroupIds;
    },
    get allGroupIds() {
      return [self.cognitoGroupId, ...self.cognitoExtraGroupIds];
    },

    /**
     * Get the MFA configuration for the user. Personal MFA configuration takes precedence over organization MFA configuration
     */
    get MFARequired() {
      if (self.personalAccountMFARequired === UserAccountMFA.UNSET) {
        return self.organizationRequireMFA;
      }
      return self.personalAccountMFARequired === UserAccountMFA.ENABLED;
    },
    get MFAEnabled() {
      if (self.mfaEnabledArray.length !== 0) {
        return true;
      }
      return false;
    },
    get UserMFAPreference() {
      return self.mfaPreferences;
    },
    get AuthSession() {
      return self.cognito_authSession;
    },
    userCan(
      accountId: string,
      permission: keyof IAccountPermissionsModel
    ): boolean {
      return self.accountTypePerAccountCollectionModel.get(accountId)
        ?.permissions[permission];
    },
  }))
  .actions((self) => ({
    addFunctionToLoadCallback(func: () => void) {
      if (self.loadStatus !== "loaded") self.loadCallbackFunctions.push(func);
      else func();
    },
    addFontsPerAccount(accountId: string, fonts: IFontsModel) {
      self.fontsPerAccountCollectionModel.put({
        id: accountId,
        fonts,
      });
    },
    getPermissionsForAccount(accountId: string) {
      return self.accountTypePerAccountCollectionModel.get(accountId)
        ?.permissions;
    },
    getAccountType(accountId: string) {
      return self.accountTypePerAccountCollectionModel.get(accountId)
        ?.levelName;
    },
    addPermissionsPerAccount(
      accountId: string,
      levelName: string,
      permissions: IAccountTypeModel
    ) {
      self.accountTypePerAccountCollectionModel.put({
        id: accountId,
        levelName,
        permissions,
      });
    },
    getFontsForAccount(accountId: string) {
      return self.fontsPerAccountCollectionModel.get(accountId)?.fonts;
    },
    setGroupId(cognitoGroupId: string) {
      self.cognitoGroupId = cognitoGroupId;
    },
    setExtraGroupIds(cognitoExtraGroupIds: string[]) {
      self.cognitoExtraGroupIds.replace(cognitoExtraGroupIds);
    },
    setUserAttributes(attr: IUserAttributesModel) {
      // const {email} = attr
      self.userAttributes = attr;
    },
    setAccountData(accountData: IAccountDataModel) {
      self.accountData = accountData;
    },
    setAccountTypePerAccount(data: { [key: string]: IAccountTypeModel }) {
      self.accountTypePerAccountCollectionModel.clear();
      Object.keys(data).forEach((key) => {
        const levelName = data[key].levelName
          ? data[key].levelName
          : "FREEMIUM";
        let permissionsFromDB: any = data[key].permissions
          ? data[key].permissions
          : {};
        let permissionsFromLevel: any = {};
        switch (levelName) {
          case "FREEMIUM":
            permissionsFromLevel = FREEMIUM_PERMISSIONS;
            break;
          case "PRO":
            permissionsFromLevel = PRO_PERMISSIONS;
            break;
          case "ENTERPRISE":
            permissionsFromLevel = ENTERPRISE_PERMISSIONS;
            break;
        }
        for (const key in permissionsFromLevel) {
          if (permissionsFromDB && permissionsFromDB[key] === undefined) {
            permissionsFromDB[key] = permissionsFromLevel[key];
          }
        }
        self.accountTypePerAccountCollectionModel.put({
          levelName: levelName,
          permissions: permissionsFromDB,
          id: key,
        });
      });
    },
    setAccountSettings(accountSettings: IAccountSettingsModel) {
      self.accountSettings = accountSettings;
    },
    setFileUploads(fileUploads: any) {
      self.fileUploads.replace(fileUploads);
    },
    setOrganizationRequireMFA(enforcedMFA: boolean) {
      self.organizationRequireMFA = enforcedMFA;
    },
    setPersonalAccountMFARequired(mfaConfig: number) {
      self.personalAccountMFARequired = mfaConfig;
    },
    setMFAPreferences(mfaPreferences: string) {
      self.mfaPreferences = mfaPreferences;
    },
    setMFAEnabledArray(mfaEnabledArray: string[]) {
      self.mfaEnabledArray.replace(mfaEnabledArray);
    },
    setCognitoAuthSession(authSession: AuthSession) {
      self.cognito_authSession = authSession;
    },

    // setFonts(fonts: IFontsModel) {
    //   self.fonts.replace(fonts);
    // },
    setFontsPerAccount(data: { [key: string]: IFontsModel }) {
      self.fontsPerAccountCollectionModel.clear();
      Object.keys(data).forEach((key) => {
        self.fontsPerAccountCollectionModel.put({
          id: key,
          fonts: data[key],
        });
      });
    },
    setLoadStatus(status: "loading" | "loaded") {
      self.loadStatus = status;
    },
  }))
  .actions((self) => ({
    async signOut(history: NavigateFunction) {
      try {
        await signOut();
        getRoot<IRootModel>(
          self
        ).projectsStore.removeProjectsFromLocalStorage();
        getRoot<IRootModel>(self).resetStore();
        history(toPath(PATHS.home));
        deleteAuthCookies(getENV());
        // window.location.reload();
      } catch (e) {
        console.log(e, "failed to log out");
      }
    },
    async loadAccountData() {
      self.setLoadStatus("loading");
      try {
        const promises = [
          fetchAuthSession(),
          fetchUserAttributes(),
          fetchMFAPreference(),
        ];
        const authResult = await Promise.all(promises);
        const fullAuth = authResult[0] as AuthSession;
        const attributes = authResult[1] as IUserAttributesModel;
        const { preferred, enabled } =
          authResult[2] as FetchMFAPreferenceOutput;
        const session = fullAuth.tokens;
        if (!session) throw new Error("No session");
        if (!session.idToken) throw new Error("No idToken");
        if (!attributes.email) throw new Error("No email");
        // Not required for now
        //if(!attributes.phone_number) throw new Error("No phone_number");
        const groups = session.idToken.payload["cognito:groups"] as string[];
        const attr: IUserAttributesModel = {
          sub: attributes.sub as string,
          email: attributes.email,
          phone_number: attributes.phone_number || null,
          identityId: fullAuth.identityId as string,
          "custom:mfaConfig": attributes["custom:mfaConfig"] || null,
        };

        if (groups.length > 1) {
          console.log("more than one group!");
        }
        if (groups.includes("blings_account")) {
          groups.splice(groups.indexOf("blings_account"), 1);
          groups.unshift("blings_account");
        }
        self.setGroupId(groups[0]);
        self.setExtraGroupIds(groups.slice(1));
        self.setUserAttributes(attr);
        self.setPersonalAccountMFARequired(
          attr["custom:mfaConfig"]
            ? parseInt(attr["custom:mfaConfig"])
            : UserAccountMFA.UNSET
        );
        self.setMFAEnabledArray(enabled || []);
        self.setCognitoAuthSession(fullAuth);
        if (preferred) {
          self.setMFAPreferences(preferred);
        }

        // Query for fonts on every group that this user belongs to
        const accountPromises = [];
        for (const group of groups) {
          const query = insertIntoGraphQlString(
            insertIntoGraphQlString(getAccount, {
              path: ["fonts"],
              key: "variants",
              value: {
                name: true,
                weight: true,
                style: true,
              },
            }),
            {
              path: ["accountType"],
              key: "permissions",
              value: {
                removeBlingLogo: true,
                aiOptimization: true,
              },
            }
          );
          accountPromises.push(
            client.graphql({
              query,
              variables: { id: group },
            })
          );
        }
        const fontsPerAccount: { [key: string]: IFontsModel } = {};
        const accountTypePerAccount: { [key: string]: any } = {};
        const accountDataResp = (await Promise.all(accountPromises)) as {
          data: GetAccountQuery & {
            getAccount?: {
              fonts?: IFontsModel | null;
              accountSettings?: IAccountSettingsModel | null;
              accountType?: IAccountTypeModel | null;
              minisiteDomain: string | null;
            } | null;
          };
        }[];
        // Load account data from the first group
        if (accountDataResp[0].data.getAccount) {
          const { projects, fileuploads, fonts, ...accountSettings } =
            accountDataResp[0].data.getAccount;
          self.setFileUploads(fileuploads || []);
          self.setAccountData(accountSettings);
          if (accountSettings.accountSettings) {
            self.setAccountSettings({
              ...accountSettings.accountSettings,
              // mfaConfig: accountSettings.accountSettings.mfaConfig || "NONE",
              mfaConfig: "NONE",
            });
            // if (accountSettings.accountSettings.mfaConfig === "ENFORCED_TOTP") {
            //   self.setOrganizationRequireMFA(true);
            // }
          }
        }
        accountDataResp.forEach((resp, i) => {
          const group = groups[i] as string;
          const { ...accountSettings } = resp.data.getAccount;
          if (resp.data.getAccount) {
            const { fonts, accountType } = resp.data.getAccount;
            fontsPerAccount[group] = fonts || [];
            accountTypePerAccount[group] = accountType || [];
          }
          if (accountSettings.accountSettings) {
            self.setAccountSettings({
              ...accountSettings.accountSettings,
              mfaConfig: accountSettings.accountSettings.mfaConfig || "NONE",
            });
            if (accountSettings.accountSettings.mfaConfig === "ENFORCED_TOTP") {
              self.setOrganizationRequireMFA(true);
            }
          }
        });
        self.setFontsPerAccount(fontsPerAccount);
        self.setAccountTypePerAccount(accountTypePerAccount);
      } catch (e) {
        console.log(e);
      }
      self.setLoadStatus("loaded");
      self.loadCallbackFunctions.forEach((func) => func());
    },
    async saveAliasId(aliasId: string) {
      try {
        this.setSaveAliasIdStatus(AsyncOpState.Saving);
        await get(
          {
            apiName: NEW_REST_API,
            path: `${NEW_REST_API__V2__ACCOUNT_ALIAS_AVAILABLE(
              self.cognitoGroupId as string,
              aliasId
            )}`,
          }
          // NEW_REST_API,
          // `${NEW_REST_API__V1__ACCOUNT_ALIAS_AVAILABLE}/${aliasId}`,
          // {}
        ).response;

        const input = { id: self.cognitoGroupId as string, aliasId };
        const resp = (await client.graphql(
          {
            query: updateAccount,
            variables: { input },
          }
          // graphqlOperation(updateAccount, { input })
        )) as {
          data: UpdateAccountMutation & {
            updateAccount: { minisiteDomain: string | null };
          };
        };
        if (resp.data.updateAccount) {
          if (!resp.data.updateAccount.minisiteDomain) {
            resp.data.updateAccount.minisiteDomain = "";
          }
          self.setAccountData(resp.data.updateAccount);
        } else {
          throw new Error("");
        }
        this.setSaveAliasIdStatus(AsyncOpState.Success);
      } catch (e) {
        this.setSaveAliasIdStatus(AsyncOpState.Error);
      }
      setTimeout(() => {
        this.setSaveAliasIdStatus(AsyncOpState.Changed);
      }, 2000);
    },
    setSaveAliasIdStatus(status: AsyncOpState) {
      self.saveAliasIdStatus = status;
    },
    /**
     * Update the account linked CSV files and set the fileToDelete to a temp folder on S3 to be deleted in 7 days
     * @param updatedFileNames A array of files to update the account information with
     * @param fileToDelete The file that was set to be deleted
     */
    async updateFileUploads(updatedFileNames: any, fileToDelete: string) {
      const input = {
        id: self.cognitoGroupId as string,
        fileuploads: updatedFileNames,
      };

      const resp = (await client.graphql(
        {
          query: updateAccount,
          variables: { input },
        }
        // graphqlOperation(updateAccount, { input })
      )) as { data: UpdateAccountMutation };
      if (resp.data.updateAccount) {
        self.setFileUploads(updatedFileNames);
      } else {
        throw new Error("");
      }

      // After updating the account information on DynamoDB, move the CSV file from the S3 public folder to the S3 temp folder
      // The temp folder has this format: public/temp/account's cognito group id/file name
      // The deletion of the file will be handled by a lifecicle rule already implemented on S3

      // Copy the file to the temp folder
      const from = { key: fileToDelete };
      const to = { key: "temp/" + self.cognitoGroupId + "/" + fileToDelete };
      await copy({
        source: from,
        destination: to,
      });
      // Delete the file from the public folder
      await remove({
        key: from.key,
      });
    },
    async connectHubspot(data: ITokenResponseIF) {
      self.hubspotConnection = JSON.stringify({
        ...data,
        updatedAt: Date.now(),
      });
      const input = {
        id: self.cognitoGroupId as string,
        integrations: { hubspot: self.hubspotConnection },
      };
      (await client.graphql({
        query: updateAccount,
        variables: { input },
      })) as {
        data: UpdateAccountMutation;
      };
    },
    async disconnectHubspot() {
      self.hubspotConnection = null;
      const input = {
        id: self.cognitoGroupId as string,
        integrations: { hubspot: self.hubspotConnection },
      };
      (await client.graphql({
        query: updateAccount,
        variables: { input },
      })) as {
        data: UpdateAccountMutation;
      };
    },
  }))
  .actions((self) => ({
    async refreshHubspotCredentials() {
      /*
      const apiName = "platformRest";
      const path = "/integration/hubspot/refreshToken";
      */
      const myInit = {
        crossDomain: true,
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body: {
          refreshToken: JSON.parse(self.hubspotConnection || "").refreshToken,
        },
      };
      const data: any = await post({
        apiName: NEW_REST_API,
        path: NEW_REST_API__V1__HUBSPOT_REFRESH_TOKEN,
        options: myInit,
      }).response;
      await self.connectHubspot(data);
    },
  }));

export default accountModel.create({});
export type IaccountModel = Instance<typeof accountModel>;
